.programs{
    background-size: cover;
   min-height: 450px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    caret-color: transparent;
    overflow: hidden;
    padding: 20px 0;
}

.program-main-title{
    color: white;
    font-size:42px;
    margin-bottom: -10px;
    font-family: solano-gothic-pro-mvb, sans-serif;
    text-transform: uppercase;
}

.programs-p{
    font-weight: 500;
    font-size: 30px;
    color: white;
    font-family: Objektiv-Mk2, sans-serif;
}

.program-description p{
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 21px;
    font-weight: 500;
    color: white;
    margin-top: 20px;
}
.program-icon-wrappper{
   width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.program-icon{
    width: 100%;
    height: auto;
    display: block;
}

.programs-bg{
    /*background-image: url("../../assets/images/Program_hero_bg.jpg");*/
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    height: 900px;
    transform: translate(0px, 0px);
    z-index: -2;
    perspective: 1000px;

}

.free-btn{
    color: white;
    background-color: #00adef;
    border: 1px solid #00adef;
    font-size: 16px;
    width: 161px;
    height: 48px;
font-weight: 500;
    margin-right: 20px;
    cursor: pointer;
    margin-top: 20px;
}


.free-btn:hover{
    background-color: #2590b9;
    border: 1px solid #2590b9;
}

.btn-ratings-parent{
    width: 58%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto 0;
    flex-direction: column-reverse;
}

.btn-ratings-parent p{
    color: white;
    font-size: 14px;
}


.overview-block{
    margin: 60px 0 40px;
}


.overview-block-title{
    font-weight: 500;
    margin-bottom: 10px;
    /*margin: 32px 0;*/
    text-align: left;
    width: 100%;
    color: #00ADEF;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    display: flex;
    align-items: center;
    caret-color: transparent;
}

.overview-block-title img{
width:30px
}


.overview-title{
    font-size: 20px;
    font-weight: 400;
    color: #6B6A6A;
    margin-bottom: 10px;
}

.overview-block-inner{
    padding: 10px 0 10px 40px;
    caret-color: transparent;

}

.overview-block-inner p{
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 19px;
    color: #434040;
    margin-bottom: 14px;
    line-height: 1.38em
;
}

.bulleted-text p{
    font-size: 17px;
    background: url("../../assets/images/Bullet.svg") 0 2px no-repeat;
    background-size: 15px;
    padding-left: 30px;
}

.logo-block{
    /*background: linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%);*/
    padding: 15px 0;
    background-size: cover;
    min-height: 240px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    caret-color: transparent;
    overflow: hidden;
    font-family: Objektiv-Mk2, sans-serif;
    color: white;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 62px;
}

.logo-bg{
    background: url("../../assets/images/session.jpg") top no-repeat;
    /*background-repeat: no-repeat;*/
    /*background-position: top;*/
    background-size: cover;
    position: absolute;
    top: -150px;
    left: 0;
    width: 100%;
    height: 800px;
    transform: translate(0px, 0px);
    z-index: -3;
    perspective: 1000px;
    opacity: 80%;
}

.info-session-title{
    font-family: Objektiv-Mk2, sans-serif;
    font-weight: 400;
    font-size: 40px;
    color: white;
    position: relative;
    opacity: 100%;
    z-index: 1;
    margin: 20px 0;
}

.info-session-inner{
display: flex;
   align-content: center;
    margin-top: 10px;
    align-items: center;
    height: 50px;
}

.info-session-inner img{
    width: 50px;
    display: block;
    margin-right: 8px;
}
.info-register{
    color: white;
    font-weight: 500;
    font-size: 36px;
    cursor: pointer;
    font-family: solano-gothic-pro-mvb, sans-serif;
    text-transform: uppercase;
    border: 2px solid white;
    padding: 0 15px;
    text-decoration: none;
  height: 100%;
    display: flex;
    align-items: center;
}

.plus{
    transform: translateX(-40px);
    opacity: 0;
    transition: 0.2s;
    color: white;
}

.p{
    color: white;
    font-size: 36px;
    font-weight: 500;
    font-family: solano-gothic-pro-mvb, sans-serif;
    text-transform: uppercase;
    transition: 0.2s;
    margin-left: -18px;
    padding: 0;
}



.info-register:hover .p{
    margin-left: 8px;
}
.info-register:hover .plus{
    transform: translateX(0);
    opacity: 1;
    margin-left: -1px;
    visibility: visible;
}

.Sdet{
    font-weight: 500;
    font-family: solano-gothic-pro-mvb, sans-serif;
    font-size: 48px;
    color: white;
    margin-top: -10px;
}


.announce-icon{
    display: block;
    position: absolute;
    top: 17%;
    left: -3%;
    width: 147px;
    opacity: 20%;
}

/*.gradient-bg{*/
/*    background-color: rgba(148,231,161,66%);*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    z-index: -1;*/
/*}*/

/*.logo-block p{*/
/*    font-size: 16px;*/
/*    font-weight: 300;*/
/*    color: #6D737A;*/
/*    padding-right: 30px;*/
/*}*/

.start-block{
    box-shadow: -4px -4px 44px rgba(0, 0, 0, 0.08);
    margin-bottom: 50px;
}

.start-block p{
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
color:#6D737A;
    font-family: Objektiv-Mk2, sans-serif;
}

.start-block img{
    display: inline-block;
    vertical-align: center;
    padding-right: 8px;
}

.register-link{
    color: #00adef;
    font-size: 32px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 16px;
    font-family: solano-gothic-pro-mvb, sans-serif;
    text-transform: uppercase;
    border: 2px solid #00ADEF;
    padding: 4px 10px;
}

.register-plus{
    transform: translateX(-40px);
    opacity: 0;
    transition: 0.2s;
    color: #00ADEF;
}

.register-btn-p{
    transition: 0.2s;
    margin-left: -13px;
    padding: 0;
}


.register-link:hover .register-plus{
    color: #00ADEF;
    transform: translateX(0);
    opacity: 1;
    margin-left: -1px;
    visibility: visible;
}


.register-link:hover .register-btn-p{
    margin-left: 8px;
}





.start-title{
    font-weight: 500;
    margin-bottom: 32px;
    /*margin: 32px 0;*/
    text-align: left;
    width: 100%;
    color: #00ADEF;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    display: flex;
    align-items: center;
    caret-color: transparent;
}

.start-title img{
    width: 30px;
}

.blue-icon{
    fill: #00ADEF;
}
.red{
    fill:  #F0410C;
}

.green-icon{
    fill: #6fa577;
}

.start_pic_wrapper img{
    width: 100%;
    height: auto;
    display: block;
}

.month_title{
    font-weight: 700;
    font-size: 20px;
    color: #6B6A6A;
    margin-bottom: 12px;
}

.weeks{
    font-size: 18px;
    font-family: Objektiv-Mk2, sans-serif;
    font-weight: 400;
    color: #434040;
    margin: 10px 0;
}

.body-copy-accordion{
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 16px;
    color: #434040;
}

.curriculum-block{
    background: linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%);
    padding: 62px 0;
}


.curriculum-accordion-parent{
    padding: 0 0 30px;
    background: white;

}

.curriculum-logo{
    /*color: white;*/
    /*font-size: 14px;*/
    /*font-weight: 700;*/
    /*background: #E74040;*/
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
    /*padding: 0 10px;*/
    /*line-height: 24px;*/
    /*margin-right: 32px;*/
    /*text-align: center;*/
    /*max-height: 50px;*/
    display: flex;
    align-content: center;

}



.accordion:hover .not-expanded{
   color: #00ADEF;
}

.expanded {
    font-family: solano-gothic-pro-mvb, sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: #00ADEF;
    margin: auto 0;
    text-transform: uppercase;
}
.not-expanded{
    color: #434040;
    font-family: solano-gothic-pro-mvb, sans-serif;
    font-weight: 500;
    font-size: 28px;
    margin: auto 0;
    text-transform: uppercase;
}


/*.flex-accordion-child{*/
/*    display: flex;*/
/*    align-content: center;*/
/*}*/

.accordion-child-curriculum{
    margin: 0 20px;
}
.accordion-sum{

}
.hover{
    color: #00ADEF;
}

.pricing-block{
    padding: 62px 0;
}

.pricing-block-title{
    font-weight: 500;
    margin-bottom: 10px;
    /*margin: 32px 0;*/
    text-align: left;
    width: 100%;
    color: #00ADEF;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    display: flex;
    align-items: center;
    caret-color: transparent;
}

.pricing-block-title img{
    width: 30px;
}

.btns-parent-pricing{
    display: flex;
    width: 80px;
    justify-content: space-between;

}

.pricing-pic-wrapper{
    width: 100%;
    height: 100%;
    display: none;
}

.pricing-pic-wrapper img{
    width: 100%;
    height: 100%;
}

.more-btn{
    font-size: 16px;
    color: #00ADEF;
    border:none;
    background-color: transparent;
    cursor: pointer;
}

.icon-btn{
    border: 1px solid #00ADEF;
    background-color: transparent;
    padding: 5px 7px;
}

.pricing-toggle-btn{
    box-shadow: 0 15px 20px rgba(24, 48, 175, 0.12);
    color: #81807F;
    font-size: 15px;
    font-weight: 600;
    font-family: Objektiv-Mk2, sans-serif;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #F2F6F9;
    border-bottom:2px solid #81807F ;
    width: 100%;
    padding: 11px 0;
    cursor: pointer;
    caret-color: transparent;
}

.pricing-toggle-btn span{
    margin-left: 66px;
}

.online{
    background: url("../../assets/images/ITField_Grey.svg") 42% 50% no-repeat;
    background-size: 28px;
}

.campus{
    background: url("../../assets/images/Campus_Grey.svg") 35% 50% no-repeat;
    background-size: 35px;
}

.pricing-toggle-btn:hover.online{
    background: url("../../assets/images/ITField_Blue.svg") 42% 50% no-repeat;
    color: #00ADEF;
    background-size: 28px;
}

.pricing-toggle-btn:hover.campus{
    background: url("../../assets/images/Campus_Blue.svg") 35% 50% no-repeat;
    color: #00ADEF;
    background-size: 35px;
}


.online-active{
    color: white;
    background: url("../../assets/images/ITField_White.svg") 42% 50% no-repeat, linear-gradient(to bottom, #00ADEF,  #00ADEF);
    background-size: 28px;

}
.campus-active{
    background:url("../../assets/images/Campus_White.svg") 35% 50% no-repeat, linear-gradient(to bottom, #00ADEF,  #00ADEF);
    color: white;
    background-size: 35px;
    border-bottom:2px solid #00ADEF ;
}



.option-icon-box{
    /*background: url("../../assets/images/arrow.png") 0 0 no-repeat;*/
    border-bottom: 1px solid #a2a2a2;
}

.option-p{
    font-size: 14px;
    color: #434040;
    font-family: Objektiv-Mk2, sans-serif;
    font-weight: 400;
}


.option-number{
    font-size: 20px;
    color: #00ADEF;
    font-weight: 700;
    font-family: Objektiv-Mk2, sans-serif;
}


.budget{
    color: #6fa577;
}

.installments{
    font-family: Objektiv-Mk2, sans-serif;
    color: #434040;
    padding: 10px 0 ;
    border-bottom: 1px solid #a2a2a2;
}


.installment-title{
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #434040;
    margin-bottom: -5px;
}

.installment-mode{
    font-weight: 400;
    color: #434040;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 13px;
    margin-bottom: 10px;
}

.no-border{
    border-bottom: none;
}

.payment-box{
    color: #6D737A;
    font-weight: 400;
    font-size: 18px;
    margin: 22px 0;
}

.payment-p{
    color: #6D737A;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin: 12px 0;
}


.payment-box p{
    display: flex;
    align-items: center;
    margin: 12px 0;
}
.payment-box img{
    display: inline-block;
    margin-right: 10px;
}

.job-offer{
    font-family: Objektiv-Mk2, sans-serif;
    color: #434040;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-content: center;
}

.job-offer a{
    text-decoration: none;
    color: #00ADEF;
    font-size: 13px;
    font-weight: 700;
}

.enroll-btn{
    color: #00adef;
    font-size: 32px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 16px;
    font-family: solano-gothic-pro-mvb, sans-serif;
    text-transform: uppercase;
    border: 2px solid #00ADEF;
    width: 70%;
    margin-bottom: 10px
}
.enroll-btn:hover{
    color: white;
    background: #00ADEF;
}

.free-tuition{
    border: 2px dotted #6fa577;
    box-shadow: -4px -4px 44px rgba(0, 0, 0, 0.08);
    font-family: Objektiv-Mk2, sans-serif;
    text-align: center;
    color: #6fa577;
    padding: 10px;
    background: url("../../assets/images/scissors.svg") 2% 8% no-repeat;
    background-size: 0;
}

.free-tuition h3{
    text-transform: uppercase;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 10px;
}

.promotion-text{
    font-size: 20px;
    max-width: 500px;
    margin: 0 auto;
}
.promotion{
    margin-top: 5px;
}

.promotion p{
    font-size: 13px;
    color: #434040;
}

.promotion a{
    text-decoration: none;
    font-weight: 700;
    color: #00ADEF;
}

@media screen and (min-width: 480px) {
    .free-tuition{
        background: url("../../assets/images/scissors.svg") 2% 8% no-repeat;
        background-size: 50px;
    }
}

@media screen and (min-width: 680px) {
    .pricing-toggle-btn{
        font-size: 16px;
    }

    .pricing-toggle-btn span{
        margin-left: 66px;
    }

    .online{
        background-size: 43px;
    }

    .campus{
        background-size: 50px;
    }

    .pricing-toggle-btn:hover.online{
        background-size: 43px;
    }

    .pricing-toggle-btn:hover.campus{
        background-size: 50px;
    }


    .online-active{
        background-size: 43px;

    }
    .campus-active{
        background-size: 50px;
    }

    .free-tuition{
        background: url("../../assets/images/scissors.svg") 2% 8% no-repeat;
        background-size: 50px;
    }

    .free-tuition h3{
        font-size: 25px;
        margin-bottom: 10px;
    }

    .promotion-text{
        font-size: 25px;
        width: 500px;
        margin: 0 auto;
    }
    .promotion{
        margin-top: 10px;
    }

    .promotion p{
        font-size: 13px;
        color: #434040;
    }


}


@media screen and (min-width: 750px) {
    .programs {
        height: 450px;
    }
    .program-main-title{
        font-size: 42px;
    }
    .programs-p{
        font-size: 30px;
    }
    .info-session-title{
        margin-left: 20px;
    }
    .pricing-pic-wrapper{
        display: flex;
    }

    .free-tuition{
        background: url("../../assets/images/scissors.svg") 2% 48% no-repeat;
        background-size: 80px;
    }

}

@media screen and (min-width: 890px) {
    .programs{
        height: 450px;
    }

    .program-main-title{
        font-size: 52px;
    }

    .programs-p{
        font-size: 36px;
    }
    .btn-ratings-parent{
        flex-direction: row;
        margin: 62px auto 0;
    }
    .free-btn{
        margin-top: 0;
    }

}


@media screen and (min-width: 900px) {
    .free-tuition{
        background: url("../../assets/images/scissors.svg") 2% 3% no-repeat;
        background-size: 50px;
    }
}




@media screen and (min-width: 1000px) {
    .program-main-title{
        font-size: 62px;
    }
    .programs{
        height: 600px;
    }

    .free-tuition{
        background: url("../../assets/images/scissors.svg") 2% 3% no-repeat;
        background-size: 50px;
    }


}

@media screen and (min-width: 1100px) {
    .program-main-title{
        font-size: 72px;
    }
    .programs{
        height: 650px;
    }
    .free-tuition{
        background: url("../../assets/images/scissors.svg") 2% 48% no-repeat;
        background-size: 80px;
    }

}