.offer-block {
    min-height: 359px;
    margin-bottom: 50px;
    flex-direction: column;
}


.offer-title-block {
    font-size: 38px;
    color: #00ADEF;
    text-align: left;
}

.offer-title-block h2 {
    width: 100%;
    text-align: center;
    color: #00ADEF;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    caret-color: transparent;
    font-weight: 500;
}

.card{
    padding: 16px;
    width: 100%;
    box-shadow: 0px -1px 54px 19px rgba(152, 159, 165, 0.2);
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.card h3{
    color: #434040;
    font-size: 19px;
    text-align: left;
    margin: 16px 0 8px;
    font-family: Objektiv-Mk2, sans-serif;
    font-weight: 700;

}

.card p{
    color: #434040;
    font-size: 16px;
    text-align: left;
    margin-bottom: 20px;
    font-family: Objektiv-Mk2, sans-serif;
    font-weight: 400;
}

.image-wrapper-offer{
    width: 100%;
    height: 137px;
    margin: 15px 0;
}

.image-wrapper-offer img{
    width: 100%;
    height: 100%;
}

.btns-parent{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid lightgrey;
    padding: 20px 0 0 0;
    margin-top: auto;
}

.more-btn{
    font-size: 16px;
    color: #00ADEF;
    border:none;
    background-color: transparent;
}

.icon-btn{
    border: 1px solid #00ADEF;
    background-color: transparent;
    padding: 5px 5px 2px;
}

.down{
    display: block;
}
.right{
    display: none;
}

@media screen and (min-width: 400px) {

    .card{
        width: 100%;
    }
}

@media screen and (min-width: 500px) {

    .card{
        width: 48%;
    }
}


@media screen and (min-width: 1000px) {

    .card{
        width: 24%;
    }
}

@media screen and (min-width: 700px) {
    .offer-block {
      flex-direction: row;
    }

}

@media screen and (min-width: 900px) {
    .down{
        display: none;
    }
    .right{
        display: block;
    }
    .offer-title-block h2 {
        font-size: 26px;
        width: 100%;
        text-align: left;
    }

}