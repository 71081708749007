.programs-block{
padding: 20px 0;
    margin-bottom: 50px;
}

.programs-box{
    height: 100%;
}

.programs-block h3{
    font-weight: 500;
    margin: 32px 0;
    text-align: left;
    width: 100%;
    color: #00ADEF;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    display: flex;
    align-items: center;
    caret-color: transparent;

}
.programs-block h3 img{
    width: 30px;
}

.image-wrapper{
    position: relative;
    width: 80%;
    height: 155px;
    margin-bottom: 20px;
    align-self: center;
}

.student-pic{
    max-width:  100%;
    height: auto;
}
.popular{
    position: absolute;
    left: 22px;
    top: 22px;
    color: white;
    font-size: 14px;
    width:74px;
    height: 24px;
    background: #E74040;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rating{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    gap: 5px;
    position: absolute;
    width: 50px;
    height: 26px;
    right: 22px;
    top: 22px;
    background: #252B42;
    border-radius: 20px;
    color: white;
    font-size: 12px;
}

.content h5{
    font-weight: 700;
    font-size: 16px;
    color: #00adef;
    margin: 16px 0;
}
.content{
    text-align: left;
    color: #4d5052;
    padding: 0 22px;
}



.program-main-page p{
    font-size: 16px;
    line-height: 20px;
    color: #6D737A;
    margin-bottom: 10px;
}

.details{
    display: flex;
    align-items: center;
    margin: 16px 0;

}

.details p{
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.learn-btn{
    /*width: 160px;*/
    color: #00adef;
    font-size: 20px;
    font-weight: 500;
    border: 2px solid #00adef;
    /*padding: 10px 0;*/
    padding: 5px 20px;
    text-transform: uppercase;
    font-family: 'Barlow Semi Condensed', sans-serif;
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 1.7em;
    text-align: center;
    margin-right: 25px;
    margin-bottom: 10px;
}

.learn-btn span{
    transform: translateX(-40px);
    opacity: 0;
    transition: 0.2s;
}


.learn-btn p {
    transition: 0.2s;
    margin-left: -18px;
    padding: 0;
}

.learn-btn:hover{
    background: #00ADEF;
    color: white;
}


.learn-btn:hover span{
    transform: translateX(0);
    opacity: 1;
    margin-left: -1px;
    visibility: visible;
}

.learn-btn:hover p{
    margin-left: 8px;
}



/*.learn-btn:hover{*/
/*    background: linear-gradient(165deg, #2b87da 0%, #29c4a9 100%);*/
/*    color: white;*/
/*}*/

.content2{
   margin-top: auto;
    width: 100%;
    padding: 0 22px;
    align-self: end ;
}

.program-swiper-container{
    padding-bottom: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

}

.navigation-btns-p {
    display: flex;
    position: absolute;
    bottom: 1px;
    right: 0;
    z-index: 555;
}


.program-swiper-container .mySwiper {
    width: 100%;
    height: 100%;
    padding: 0 0 52px;
    display: flex;
    flex-direction: column;
}


.program-swiper-container .swiper-slide{
    height: auto;
}

@media screen and (min-width: 500px) {
    .image-wrapper{
        position: relative;
        width: 80%;
        height: 298px;
        margin-bottom: 30px;
        align-self: center;
    }

}
@media screen and (min-width: 400px) {
    .image-wrapper{
        position: relative;
        width: 80%;
        height: 195px;
        margin-bottom: 30px;
        align-self: center;
    }

}
@media screen and (min-width: 500px) {
    .image-wrapper{
        position: relative;
        width: 80%;
        height: 250px;
        margin-bottom: 30px;
        align-self: center;
    }

}

@media screen and (min-width: 550px) {
    .image-wrapper{
        height: 280px;
    }

}


@media screen and (min-width: 610px) {
    .image-wrapper{
        width: 80%;
        height: 300px;
    }

}

@media screen and (min-width: 710px) {
    .image-wrapper{
        width: 80%;
        height: 324px;
    }

}



@media screen and (min-width: 740px) {
    .image-wrapper{
        width: 80%;
        height: 179px
    }

}



@media screen and (min-width: 880px) {
    .image-wrapper{
        width: 80%;
        height:238px;
    }

}



@media screen and (min-width: 980px) {
    .image-wrapper{
        width: 80%;
        height:298px;
    }

}