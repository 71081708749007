.card-Modal{
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;  /* mozilla */

}

.card-Modal::-webkit-scrollbar {
    display: none; /* Safari and Chrome browsers */
}

.image-wrapper-modal{
    /*max-width: 500px;*/
    max-height: 500px;
    height: auto;
    overflow: hidden;
}

.image-wrapper-modal img{
    width: 100%;
    height: auto;
}

.modal-title{
    color: #00ADEF;
    font-family: "Helvetica Neue",sans-serif;
    margin-bottom: 10px;
    font-size: 18px;
}