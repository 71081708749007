.courses-text{
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    margin: 20px;
    font-family: Objektiv-Mk2, sans-serif;
    color: #231f20;
}

@media screen and (min-width: 840px) {
    .courses-text{
        margin: 20px 100px;
    }
}

.courses-text-author{
    margin: 20px 0 0;
}
.courses-box-container{
    display: flex;
    padding: 0 30px;
    justify-content: space-between;
    caret-color: transparent;
}
.courses-box1{
    width: 15%;
    font-family: solano-gothic-pro-mvb, sans-serif;
    font-size: 60px;
    font-weight: 500;
    color: #00adef;
}

.different-number{
    color: #F0410C;
}

.different-number2{
    color: #94e7a1;
}

.courses-text-subtext{
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 14px;
    color: #231f20;
    margin: 0;

}

.courses-box2{
    width: 80%;
    padding-top: 20px;
    padding-right: 20px;
}

.courses-box2 h3{
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: Objektiv-Mk2, sans-serif;
}

.courses-box2 p{
    color: #231f20;
    font-size: 14px;
    font-weight: 300;
    font-family: Objektiv-Mk2, sans-serif;
}

.blue{
color: #00ADEF;
}
.red{
    color: #F0410C;
}
.green{
    color: #94e7a1;
}