

.bootcamp-block{
    background: url("../../assets/images/bg2_transparent.png"), linear-gradient(337deg, rgba(12,126,171,1) 0%, rgba(0,173,239,1) 100%) ;
background-size: cover;
    padding: 32px 0 52px;
    color: #1E1E1E;
}

.bootcamp-block h3{
    text-align: left;
    margin-bottom: 32px;
    font-weight: 500;
    width: 100%;
    color: white;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    display: flex;
    align-items: center;
    caret-color: transparent;

}

.bootcamp-block h3 img{
    width: 30px;
}

.work-box{
    background: white;
    box-shadow: 0px -1px 54px 5px rgba(152, 159, 165, 0.2);
    padding: 15px 7px ;
    border: 1px solid white;
    color: #434040;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.work-box:hover{
    border: 1px solid #00ADEF;
}

.work-box p{
    font-family: Objektiv-Mk2, sans-serif;
}

.work-box img{
    width: 14px;
}
/*.work-box:hover{*/
/*    color: #00ADEF;*/
/*    border-color: #00ADEF;*/
/*}*/