
.campus-gradient{
    background: linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%);
    padding: 32px 0 62px;
    margin-bottom: 32px;
}

.campus-img-wrapper img{
    width: 100%;
    height: auto;
    display: block;
}

.campus-img-wrapper {
    -webkit-box-shadow: 4px 21px 22px -7px rgba(186, 186, 186, 0.35);
    -moz-box-shadow: 4px 21px 22px -7px rgba(186, 186, 186, 0.35);
    box-shadow: 4px 21px 22px -7px rgba(186, 186, 186, 0.60);
    margin-top: 27px;
    /*background: url("/src/assets/images/campus.jpg");*/
    /*background-size: auto;*/
    min-height: 277px;
    display: flex;
    justify-content: right;
}

.align-right{
    text-align: right;
    padding: 20px;
    align-self: center;
    width: 100%;
font-size: 25px;
    color: white;
    font-weight: 600;
    font-family: Objektiv-Mk2, sans-serif;
}

@media screen and (min-width: 540px) {
    .align-right{
        font-size: 30px;
    }
}



@media screen and (min-width: 900px) {
    .align-right{
        width: 60%;
    }
}


@media screen and (min-width: 1000px) {
    .align-right{
        width: 60%;
    }
}

@media screen and (min-width: 1100px) {
    .align-right{
        width: 40%;
    }
}

.campus-learn-btn{
    margin-top: 20px;
    width: 155px;
    height: 48px;
    color: white;
    font-size: 24px;
    background-color: transparent;
    font-family: Objektiv-Mk2, sans-serif;
    font-weight: 400;
    border: 1px solid white;

}
.campus-learn-btn:hover{
    border: 1px solid #00ADEF;
    color: #00ADEF;
}
