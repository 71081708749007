.apply-btn {
    font-family: solano-gothic-pro-mvb, sans-serif;
    font-weight: 500;
    background: linear-gradient(165deg, #2b87da 0%, #29c4a9 100%);
    color: white;
    text-transform: uppercase;
    border-radius: 0;
    transition: 0.2s;
    display: flex;
    align-items: center;
    caret-color: transparent;
    width: 120px;
    height: 40px;
    font-size: 12px;
    cursor: pointer;
}

.apply-btn span {
visibility: hidden;
    transform: translateX(-50px);
    margin-right: 10px;
}

.apply-btn-parent{
    width:80px;
    display:flex;
    justify-content: right;
    padding: 2px 5px;
}

.apply-btn p {
    transition: 0.2s;
    margin-left: -20px;
}

.program-menu{
    position: relative;
    z-index: 67;
}

.drop-menu{
    position: absolute;
    top: 80px;
    left: 7px;
    background: white;
    color: #81807f;
    width: 338px;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 18px;
    z-index: 66;
}

.drop-menu li{
    list-style: none;
    padding: 10px 5px 10px 20px;
    cursor: pointer;
    caret-color: transparent;
    text-transform: capitalize;
}


.drop-menu a{
    text-decoration: none;
    color: #81807f;
    text-transform: capitalize;
}
.drop-menu li:hover{
    background: #aae0f7;
}


.drop-mobile-menu{
    background: white;
    color: #81807f;
    width: 338px;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 15px;
    z-index: 78;
    -webkit-box-shadow: 6px -2px 35px 9px rgba(49, 50, 51, 0.2);
    -moz-box-shadow: 6px -2px 35px 9px rgba(49, 50, 51, 0.2);
    box-shadow: 6px -2px 35px 9px rgba(49, 50, 51, 0.2);
    margin-left: 10px;
}

.drop-mobile-menu li{
    list-style: none;
    padding: 5px 5px 5px 6px;
    cursor: pointer;
    caret-color: transparent;
    text-transform: capitalize;
}

.drop-mobile-menu a{
    text-decoration: none;
    color: #81807f;
    text-transform: capitalize;
}

.drop-mobile-menu li:hover{
    background: #aae0f7;
}



@media screen and (min-width: 700px) {
    .apply-btn {
        font-family: solano-gothic-pro-mvb, sans-serif;
        font-weight: 600;
        background: linear-gradient(165deg, #2b87da 0%, #29c4a9 100%);
        color: white;
        text-transform: uppercase;
        border-radius: 0;
        width: 154px;
        height: 60px;
        transition: 0.2s;
        display: flex;
        align-items: center;
        caret-color: transparent;
        font-size: 32px;
    }
    .apply-btn:hover {
        width: 200px;
    }

    .apply-btn span {
        transform: translateX(-40px);
        opacity: 0;
        transition: 0.2s;
        margin-right: -5px;
    }

    .apply-btn p {
        transition: 0.2s;
        margin-left: 0px;
    }

    .apply-btn:hover span{
        transform: translateX(0);
        opacity: 1;
        margin-left: 20px;
        visibility: visible;
    }

    .apply-btn:hover p{
        margin-left:20px;
        letter-spacing: -0.6px;
    }
    .apply-btn-parent{
        width:210px;
    }
}