
/*.iframe-wrap{*/
/*    width: 100vw;*/

/*}*/

.blog-wrapper{
  padding: 20px 0;
  position: relative;
}

.blog-iframe-wrapper{
  margin-bottom: 20px;
}
.blog-content-wrapper{
  margin-bottom: 60px;
}

.blog-main-title{
  font-size: 36px;
  color: #00ADEF;
  font-family: solano-gothic-pro-mvb, sans-serif;
  margin-bottom: 20px;
}

.post-pic{
  max-width: 580px;
  width: 100%;
  height: auto;
}

.post-block{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
}


.to-top-button {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 120px;
  z-index: 1;
  cursor: pointer;
  right: 2%;
  border-radius: 50px;
  padding: 0px;
  border: none;
  opacity: 0.9;
  background-color: whitesmoke;
}

.to-top-button:hover{
  opacity: 1;
}