.mission-title{
    font-weight: 500;
    margin-bottom: 10px;
    /*margin: 32px 0;*/
    text-align: left;
    width: 100%;
    color: #00ADEF;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    display: flex;
    align-items: center;
    caret-color: transparent;
}
.mission-title img{
    width: 30px;
}

.mission-text{
    font-weight: 400;
    font-size: 14px;
    color: #444141;
    padding: 0 0 10px 0;
    font-family: Objektiv-Mk2, sans-serif;
    line-height: 1.6em;
}


.mission-text p{
    font-weight: 400;
    font-size: 14px;
    color: #444141;
    padding: 0 0 10px 0;
    font-family: Objektiv-Mk2, sans-serif;
    line-height: 1.6em;
}
.mission-pic-wrapper{
    width: 100%;
    height: auto;
    position: relative;
    background-color: rgba(0,0,0,33%);
    z-index: 5;
}

.mission-pic{
    width: 100%;
    height: auto;
    display: block;
}


.mission-gradient{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(0,0,0,0.33) 0%, rgba(0,0,0,0.33) 40%, rgba(0,0,0,0.33) 100%);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    caret-color: transparent;
}

.mission-video-play-btn{
    width: 90px;
    height: 90px;
}

@media screen and (min-width: 900px) {
    .mission-text{
        padding-right: 62px;
    }
}

