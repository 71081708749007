.faq-block{
    background: linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%);
    padding: 20px 0;
}

.faq-block-main{
    display: flex;
    align-content: center;
    margin-bottom: 20px;
}


.faq-block-main h3{
    font-weight: 500;
    text-align: left;
    width: 100%;
    color: #00ADEF;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 24px;
    display: flex;
    align-items: center;
    caret-color: transparent;

}

.faq-block-main img{
    width: 28px;
    display: block;
}

.faq-block-text{
   font-family: Objektiv-Mk2, sans-serif;
    font-size: 14px;
    color: #434040;
}

.faq-block-text p{
    padding-left: 38px;
    margin: 0 auto 20px ;
}

.faq-block-text a{
    color: #00ADEF;
    font-weight: 600;
    text-decoration: none;
}

.faq-img-wrapper{
    width: 100%;
    height: 100%;
}
.faq-img-wrapper img{
    width: 100%;
    height: auto;
    display: block;
}


.accordion-parent{
    width: 100%;
    background-color: white;
    box-shadow: -4px -4px 44px rgba(0, 0, 0, 0.08);
    padding-bottom: 20px;
}
.accordion-child{
    margin: 0 10px;
}

.questions-block{
    background: #FFFFFF;
    padding: 22px 10px;
}

.questions-title{
    font-family: Objektiv-Mk2, sans-serif;
font-size: 22px;
    font-weight: 500;
}

.question-expanded{
    color: #00ADEF;
}

.question-closed {
    color: #434040;
}

.ask-us{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 200px;
    margin-bottom: 50px;
    align-content: center;
    align-items: center;
}



.ask-us p{
    font-family: Objektiv-Mk2, sans-serif;
    color: #434040;
    font-size: 24px;
    font-weight: 600;
}

.ask-us button{
    color: #00adef;
    font-size: 32px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 16px;
    font-family: solano-gothic-pro-mvb, sans-serif;
    text-transform: uppercase;
    border: 2px solid #00ADEF;
    padding: 4px 10px;
}


.ask-us button:hover{
    color: white;
    background: #00ADEF;
}

@media screen and (min-width: 850px) {
    .accordion-child{
        margin: 0 102px;
    }
    .questions-block{
        padding: 62px 37px;
    }
}




/*.chips-box {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*}*/
.questions-button{
    color: #81807F;
    background-color: white;
    border: 1px solid #81807F;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 18px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}


@media screen and (min-width: 680px){
    .questions-button{
        font-size: 22px;
        width: 100%;
        height: 60px;
        justify-content: center;
        align-items: center;
    }

    .question-button img{
        width: 100%;
    }

}


.questions-button:hover{
    border: 1px solid #00ADEF;
    color: #00ADEF;
}

.active{
    background-color: #00ADEF;
    border: 1px solid #00ADEF;
    color: white;
}

.active:hover{
    color: white;
}

.questions-block img{
    height: 60%;
    display: block;
    margin-right: 10px;
}
