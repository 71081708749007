

.swiper-container-1 .swiper .slide-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.swiper-container-1 .swiper {
  cursor: grab;
  background: white;
  height: 300px;
}

.swiper-container-1 .swiper::before{
  z-index: 2;
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  translate: -50% 0;
  width: 800vw;
  height: 798vw;
  background: white;
  border-radius: 50%;
}

.swiper-container-1 .swiper::after{
  z-index: 2;
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  translate: -50% 0;
  width: 800vw;
  height: 798vw;
  background: white;
  border-radius: 50%;
}

.swiper-container-1 .swiper::before {
  top: -794vw;
}

.swiper-container-1 .swiper::after {
  bottom: -792vw;
}


.feedback-block{
  padding: 5px 0;
  background: white;
}

.feedback-slider-img-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
}


.feedback-box-gradient{
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(0,0,0,0.33) 0%, rgba(0,0,0,0.33) 40%, rgba(0,0,0,0.33) 100%);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-img{
  width: 30px;
}

.feedback-block h3{
  font-weight: 500;
  margin: 32px 0;
  text-align: left;
  width: 100%;
  color: #00ADEF;
  font-family: Objektiv-Mk2, sans-serif;
  font-size: 26px;
  display: flex;
  align-items: center;
  caret-color: transparent;
}

.feedback-block h3 img{
  width: 30px;
}