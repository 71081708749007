.footer{
    background: #434040;
    padding: 42px 0 0;
    margin-top: 20px
}

.footer p{
    font-size: 17px;
    color: white;
    font-weight: 500;
    font-family: 'League Spartan',Helvetica,Arial,Lucida,sans-serif;;
}

.social-icons-btns-footer{
    display: flex;
}

.social-icons-btns-footer a{
    width:38px;
    text-decoration: none;
    font-size: 19px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
}