

*,
*::after,
*::before{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
}

.container{
    max-width: 1440px;
    padding: 17px 50px;
    margin: 0 auto;
    height: 82px;
}

body{
    font-family: "Helvetica Neue",sans-serif;

}