.player-wrapper {
    /*position: relative;*/
    width: 100%;
    height: 100%;
    margin-top: 15px;
    /*padding-top: 56.25%; !* 720 / 1280 = 0.5625 *!*/
}

/*.react-player {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*}*/

.player-title {
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin-top: 5px;
    text-align: left;
}

