.gradient-block {
    width: 100%;
    min-height: 161px;
    /*background-color: #00ADEF;*/
    background: linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%);
    margin-bottom: 20px;
}

.parent-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.box {
    width: 90%;
    /*color: white;*/
    color: #00adef;
    text-align: center;
    margin: 10px 0;
}

.number {
    font-size: 22px;
    font-weight: 700;
    padding-bottom:1px
}

.description{
    font-size: 14px;
    font-weight: 400;
}
@media screen and (min-width: 900px) {
    .gradient-block {
        margin-bottom: 80px;
    }
}

@media screen and (min-width: 600px) {
.parent-box{
    justify-content: space-evenly;
}

    .box {
        width: 99%;
        text-align: center;
        margin: 32px 0;
    }

    .box-5{
        padding-left: 10px;
    }

    .box-2{
        padding-left: 22px;
    }

    .number {
        font-size: 32px;
        font-weight: 700;
        padding-bottom:15px
    }

    .description{
        font-size: 20px;
        font-weight: 400;
    }


}

@media screen and (min-width: 900px) {
    .parent-box {
        justify-content: space-between;
    }
    .number {
        font-size: 42px;
        font-weight: 700;
        padding-bottom:15px
    }

    .description{
        font-size: 20px;
        font-weight: 400;
    }
}


