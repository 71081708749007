
.main-block {
    padding: 50px 0 50px;
    background-size: cover;
    overflow: hidden;
}




.new-bg {
    background-image: url("../../assets/images/background.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1050px;
    transform: translate(0px, 0px);
    z-index: -2;

}


.new-bg-gradient {
    background-image: linear-gradient(180deg, #00adef 0%, #113a4b 100%);
    mix-blend-mode: multiply;
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 878.784px;
    z-index: -1;
}

.text-block {
    width: 100%;
    text-align: center;
    color: white;
}

.inner-block {
    display: flex;
    flex-direction: column;
    z-index: 1;
}


.main-block-main-title {
    font-family: solano-gothic-pro-mvb, sans-serif;
    text-transform: uppercase;
    font-size: 35px;
    color: white;
    margin-top: -13px;
    animation: glow 3s ease-in-out infinite alternate;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
        text-shadow: 0 0 2px #fff,
        0 0 3px #fff,
        0 0 2px rgba(240, 65, 12, 60%),
        0 0 3px rgba(240, 65, 12, 60%),
        0 0 3px rgba(240, 65, 12, 60%),
        0 0 3px rgba(240, 65, 12, 60%),
        0 0 3px rgba(240, 65, 12, 60%);
    }

    to {
        text-shadow: 0 0 4px #fff,
        0 0 5px #fff,
        0 0 3px rgba(240, 65, 12, 70%),
        0 0 4px rgba(240, 65, 12, 70%),
        0 0 4px rgba(240, 65, 12, 70%),
        0 0 4px rgba(240, 65, 12, 70%),
        0 0 4px rgba(240, 65, 12, 70%);
    }
}



.text-block p {
    margin: 20px 0;
    padding-right: 20px;
}

.picture-block {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
}

.picture-box {
    max-width: 200px;
    height: auto;
    /*display: flex;*/
    /*  justify-content: center;*/
}

.pic {
    max-width: 100%;
    height: auto;
}

.sub-title {
    line-height: 1.2em;
    font-family: solano-gothic-pro-mvb, sans-serif;
    font-weight: 600;
    font-size: 24px;
}

.main-block-p {
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4em;
}

@media screen and (min-width: 600px) {
    .picture-box {
        max-width: 300px;
        height: auto;
        /*display: flex;*/
        /*  justify-content: center;*/
    }
    .main-block-main-title {
        font-family: solano-gothic-pro-mvb, sans-serif;
        text-transform: uppercase;
        font-size: 55px;
        color: white;
        margin-top: -13px;
        animation: glow 3s ease-in-out infinite alternate;
        -webkit-animation: glow 1s ease-in-out infinite alternate;
        -moz-animation: glow 1s ease-in-out infinite alternate;
    }


}


@media screen and (min-width: 700px) {
    .inner-block {
        flex-direction: row;
    }

    .picture-block {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
    }

    .text-block {
        width: 100%;
        text-align: left;
        color: white;
    }

    .sub-title {
        line-height: 1.2em;
        font-family: solano-gothic-pro-mvb, sans-serif;
        font-weight: 600;
        font-size: 30px;
    }

    .main-block-p {
        font-family: Objektiv-Mk2, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4em;
    }

    .main-block {
        padding: 70px 0 70px;
        background-size: cover;
    }

    .main-block-main-title {
        font-size: 40px;
        margin-top: -13px;
    }

    .pic {
        max-width: 100%;
        height: auto;
    }

    .picture-block {
        width: 100%;
    }

    .text-block {
        width: 100%;
    }

    /*.picture-box{*/
    /*    max-width:300px;*/
    /*    height: auto;*/
    /*    !*display: flex;*!*/
    /*    !*  justify-content: center;*!*/
    /*}*/
}

@media screen and (min-width: 820px) {

    .picture-box {
        max-width: 510px;
        height: auto;
        /*display: flex;*/
        /*  justify-content: center;*/
    }

    .picture-block {
        width: 50%;
    }

    .text-block {
        width: 50%;
    }
}


@media screen and (min-width: 915px) {
    .new-bg {
        height: 800px;
    }

    .new-bg-gradient {
        height: 800px;
    }


}


@media screen and (min-width: 1000px) {
    .inner-block {
        flex-direction: row;
    }

    .picture-block {
        width: 50%;
        display: flex;
        align-content: center;
        justify-content: center;
    }

    .text-block {
        width: 50%;
        text-align: left;
        color: white;
    }

    .sub-title {
        line-height: 1.2em;
        font-family: solano-gothic-pro-mvb, sans-serif;
        font-weight: 600;
        font-size: 31px;
    }

    .main-block-p {
        font-family: Objektiv-Mk2, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.6em;
    }

    .main-block-main-title {
        font-size: 55px;
        margin-top: -13px;
    }

    .new-bg {
        height: 900px;
    }
}


@media screen and (min-width: 1100px) {
    .main-block {
        padding: 70px 0 40px;
        height: 739px;
        background-size: cover;
        overflow: hidden;
    }

    .new-bg {
        height: 1000px;
    }

    .new-bg-gradient {
        height: 878.784px;
    }
}


