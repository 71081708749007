.apply-input {
    width: 100%;
    height: 56px;
    padding: 16px;
    background: linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%);
    margin-bottom: 22px;
    border: 1px solid lightgrey;
    color: #262626;
}


.application-btn {
    width: 100%;
    color: #00adef;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #00adef;
    padding: 10px 0;
    transition: 0.5s;
    cursor: pointer;
}


.submit-btn {
    background: linear-gradient(165deg, #2b87da 0%, #29c4a9 100%);
    color: white;
    transition: 0.5s;
    width: 100%;
    font-weight: 500;
    padding: 10px 0;
    cursor: pointer;
    border: 1px solid #00adef;
}

.submit-btn:hover {
    background: linear-gradient(165deg, #29c4a9 0%, #2b87da 100%);
}

.application-btn:hover {
    background: linear-gradient(165deg, #2b87da 0%, #29c4a9 100%);
    color: white;
}


.apply-title {
    font-weight: 500;
    /*margin-bottom: 10px;*/
    margin: 0 0 10px;
    text-align: left;
    width: 100%;
    color: #00ADEF;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    display: flex;
    align-items: center;
    caret-color: transparent;
}

.apply-title img {
    width: 30px;
}

.apply-p {
    font-size: 17px;
    font-family: Objektiv-Mk2, sans-serif;
    color: #434040;
    padding-left: 45px;
    max-width: 800px;
    margin-bottom: 50px;
}

/*.apply-container button{*/
/*    display: block;*/
/*    padding: 20px 10px ;*/
/*    background: #81807F;*/
/*}*/

.apply-modal-input {
    width: 100%;
    height: 56px;
    padding: 16px;
    background: #f5f5f5;
    margin-bottom: 28px;
    font-size: 17px;
    color: #434040;
    font-family: Objektiv-Mk2, sans-serif;
    position: relative;

}

.apply-modal-input:focus {
    border: 1px solid #00ADEF;
}


.apply-modal-input::placeholder {
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #abaaa9;
}

.apply-modal-title {
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    color: #00ADEF;
    text-align: center;
    margin-bottom: 20px;
}

.text-error{
    border:1px solid #F0410C;
}

.required{
    font-family: Objektiv-Mk2, sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #F0410C;
    margin-top: -20px;
    margin-bottom: 5px;
    margin-left: 20px;
}

.step-img {
    margin: 10px 0 40px;
    display: block;
    caret-color: transparent;
}

.next-btn-title {
    color: #00adef;
    font-size: 21px;
    font-weight: 500;
    cursor: pointer;
    font-family: solano-gothic-pro-mvb, sans-serif;
    text-transform: uppercase;
    border: 2px solid #00ADEF;
    padding: 4px 10px 4px 4px;
    margin-left: 10px;
    display: flex;
}

@media screen and (min-width: 700px) {
    .next-btn-title{
        font-size: 32px;
        padding: 4px 15px 4px 4px;
    }
}




.hidden {
    visibility: hidden;
}

.referral-p {
    color: #81807f;
    font-size: 14px;
    font-family: Objektiv-Mk2, sans-serif;
    padding: 0 20px 30px 10px;
    margin-top: -10px;
    line-height: 20px;
}


.select-apply {
    background: url('../../assets/images/grey-arrow-down.png') no-repeat 97% 50% #f5f5f5;
    -webkit-appearance: none;
    /*background-position-x: 20px;*/
    color: #abaaa9;
}

.select-apply option:hover {
    background: #00ADEF;
}

.select-apply:focus {
    background: url('../../assets/images/arrow-down.png ') no-repeat 97% 50% #f5f5f5;
    /*background-position-x: 244px;*/
    border: 1px solid #00ADEF;
    color: #434040;
}


.next-btn-span {
    transform: translateX(-30px);
    opacity: 0;
    transition: 0.2s;
    color: #00ADEF;
}

.next-btn-p {
    transition: 0.2s;
    margin-right: 15px;
    margin-left: -5px;
    padding: 0;
}

.next-btn-title:hover .next-btn-span {
    color: #00ADEF;
    transform: translateX(-10px);
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
}


.next-btn-title:hover .next-btn-p {
    margin-right: 8px;
    margin-left: 1px;
}


.nav-btns {
    color: #81807f;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 16px;
    text-transform: uppercase;
}

.nav-btns:hover {
    color: #00ADEF;
}


.option-toggle-btn {
    box-shadow: 0 15px 20px rgba(24, 48, 175, 0.12);
    color: #81807F;
    font-size: 14px;
    font-weight: 600;
    font-family: Objektiv-Mk2, sans-serif;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #F2F6F9;
    border-bottom: 2px solid #81807F;
    width: 100%;
    padding: 11px 0;
    cursor: pointer;
    caret-color: transparent;
}

.toggle-btn-box{
    display: flex;
    flex-direction: column;
}

.option-toggle-btn span {
    margin-left: 10px;
}

/*.apply-online {*/
/*    background: url("../../assets/images/ITField_Grey.svg") 29% 50% no-repeat;*/
/*    background-size: 35px;*/
/*}*/

/*.apply-campus {*/
/*    background: url("../../assets/images/Campus_Grey.svg") 17% 50% no-repeat;*/
/*    background-size: 42px;*/
/*}*/

.apply-online-active {
    color: white;
    background:  linear-gradient(to bottom, #00ADEF, #00ADEF);
}

.apply-campus-active {
    background: linear-gradient(to bottom, #00ADEF, #00ADEF);
    color: white;
    border-bottom: 2px solid #00ADEF;
}


@media screen and (min-width: 470px) {


    .option-toggle-btn {
        box-shadow: 0 15px 20px rgba(24, 48, 175, 0.12);
        color: #81807F;
        font-size: 15px;
        font-weight: 600;
        font-family: Objektiv-Mk2, sans-serif;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #F2F6F9;
        border-bottom: 2px solid #81807F;
        width: 100%;
        padding: 11px 0;
        cursor: pointer;
        caret-color: transparent;
    }

    .option-toggle-btn span {
        margin-left: 60px;
    }

    .apply-online {
        background: url("../../assets/images/ITField_Grey.svg") 29% 50% no-repeat;
        background-size: 45px;
    }

    .apply-campus {
        background: url("../../assets/images/Campus_Grey.svg") 17% 50% no-repeat;
        background-size: 52px;
    }

    .apply-online-active {
        color: white;
        background: url("../../assets/images/ITField_White.svg") 29% 50% no-repeat, linear-gradient(to bottom, #00ADEF, #00ADEF);
        background-size: 45px;

    }

    .apply-campus-active {
        background: url("../../assets/images/Campus_White.svg") 17% 44% no-repeat, linear-gradient(to bottom, #00ADEF, #00ADEF);
        color: white;
        background-size: 52px;
        border-bottom: 2px solid #00ADEF;
    }


}
/*.option-toggle-btn:hover.apply-online{*/
/*    background: url("../../assets/images/ITField_Blue.svg") 42% 50% no-repeat;*/
/*    color: #00ADEF;*/
/*    background-size: 28px;*/
/*}*/

/*.option-toggle-btn:hover.apply-campus{*/
/*    background: url("../../assets/images/Campus_Blue.svg") 35% 50% no-repeat;*/
/*    color: #00ADEF;*/
/*    background-size: 35px;*/
/*}*/


.step-two-error{
    font-family: Objektiv-Mk2, sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #F0410C;
    margin-bottom: 10px;
}

.step-four-error{
    font-family: Objektiv-Mk2, sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #F0410C;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 10px;
}




.environment {
    font-size: 17px;
    color: #434040;
    font-family: Objektiv-Mk2, sans-serif;
    margin-left: 18px;
    margin-bottom: 12px;
}

.apply-enroll-btn{
    color: #00adef;
    font-size: 32px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 16px;
    font-family: solano-gothic-pro-mvb, sans-serif;
    text-transform: uppercase;
    border: 2px solid #00ADEF;
    width: 70%;
    margin-bottom: 10px
}

.selected {
    background-color: #00ADEF;
    color: white;
}

/*.confirmation{*/
/*    max-width: 80%;*/
/*    height: auto;*/
/*}*/

.confirmation-bg{
    background:  url("../../assets/images/Confirmation_picture.png") top no-repeat;
    /*background-repeat: no-repeat;*/
    /*background-position: top;*/
    background-size: cover;
    width: 100%;
    height: 656px;
    transform: translate(0px, 0px);
    z-index: -3;
    perspective: 1000px;
    position: relative;
}
.confirmation-gradient{
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 50%;
    position: absolute ;
    top:0;
    left: 0;
}

.confirmation-inner p{
    color: white;
    opacity: 1;
}
.confirmation-title{
    font-family: solano-gothic-pro-mvb, sans-serif;
    font-size: 68px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 30px ;
}

.confirmation-info{
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 55;
}

.confirmation-info p{
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    text-align: center;
    color: white;
  width: 80%;
    margin: 0 auto 35px;
}

.confirmation-icons{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 20px auto 0 ;
}

.confirmation-icons-a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin: 20px auto 0 ;
}


.confirmation-icons p{
    font-family: solano-gothic-pro-mvb, sans-serif;
    font-size: 32px;
    color: #00ADEF;
    font-weight: 600;
    width: 100%;
}

.confirmation-icons-a img{
    width: 20px;
    height: 20px;
    display: block;
}


@media screen and (min-width: 740px) {
    .confirmation-icons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 20px auto 0 ;
        flex-direction: row;
    }

    .confirmation-icons-a{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px auto 0 ;
    }


    .confirmation-icons p{
        font-family: solano-gothic-pro-mvb, sans-serif;
        font-size: 32px;
        color: #00ADEF;
        font-weight: 600;
    }

    .confirmation-icons-a img{
        width: 50px;
        height: 50px;
        display: block;
    }
}