.team-title {
    font-weight: 500;
    margin-bottom: 10px;
    /*margin: 32px 0;*/
    text-align: left;
    width: 100%;
    color: #00ADEF;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    display: flex;
    align-items: center;
    caret-color: transparent;
}

.team-title img {
    width: 30px;
}

.swiper-wrapper {
    box-sizing: border-box;
}

.team-swiper-container {
    position: relative;
}

.team-swiper-container .mySwiper {
    max-width: 100%;
    height: 100%;
    padding: 40px 0 52px;
}

.team-swiper-container .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.team-swiper-container .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.flip-card-inner {
    width: 100%;
    height: 100%;
    transition: transform 1180ms;
    transform-style: preserve-3d;
}

.flip-card-inner.is-flipped {
    transform: rotateY(180deg);
}


.flip-card {
    position: relative;
    width: 353px;
    height: 380px;
    perspective: 900px;

}


.team-card {
    /*max-width: 353px;*/
    width: 100%;
    box-shadow: -4px -4px 44px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid #d4d4d3;
    overflow: hidden;
    height: 100%;
}


.team-back,
.team-front {
    position: absolute;
    width: 100%;
    height: auto;
    backface-visibility: hidden;
}

.team-back {
    transform: rotateY(180deg);
    background: rgb(12, 126, 171);
    background: linear-gradient(337deg, rgba(12, 126, 171, 1) 0%, rgba(0, 173, 239, 1) 100%);
    color: white;
    font-family: Objektiv-Mk2, sans-serif;
    font-weight: 500;
}


.back-text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: linear-gradient(337deg, rgba(12, 126, 171, 1) 0%, rgba(0, 173, 239, 1) 100%);
    display: flex;
    flex-direction: column;
}

.p-container {
    margin: 20px 10px;
}

.back-text p {
    text-align: left;
    margin: 10px;
    font-size: 16px;
}

.flip-icons-box {
    margin-top: auto;
    text-align: right;
}


.flip-icons-box a {
    display: inline-block;
    text-decoration: none;
    width: 40px;
    height: auto;
    margin-right: 10px;
}

.flip-icons-box img {
    display: block;
}

.team-card:hover {
    border: 1px solid #00ADEF;
}

.team-card img {
    width: 100%;
    height: auto;

}

.team-text {
    position: relative;
    text-align: left;
    padding: 32px 0 20px 60px;
    background: white;
    font-size: 16px;
    color: #6B6A6A;
    font-weight: 600;
}

.team-text span {
    color: #6D737A;
    font-weight: 400;
    font-size: 14px;
}


@media screen and (min-width: 740px) {
    .p-container {
        margin: 5px 5px;
    }

    .back-text p {
        text-align: left;
        margin: 5px;
        font-size: 13px;
    }

    .flip-icons-box a {
        display: inline-block;
        text-decoration: none;
        width: 20px;
        height: auto;
        margin-right: 10px;
    }

    .team-text {
        padding: 32px 0 20px 60px;
        font-size: 16px;
    }

    .arrow-icon-wrapper {
        position: absolute;
        top: 34px;
        left: 10px;
    }
}


@media screen and (min-width: 847px) {
    .flip-icons-box {
        margin-top: auto;
        text-align: right;
    }

    .p-container {
        margin: 5px 5px;
    }

    .back-text p {
        text-align: left;
        margin: 10px;
        font-size: 14px;
    }

    .flip-icons-box a {
        display: inline-block;
        text-decoration: none;
        width: 40px;
        height: auto;
        margin-right: 10px;
    }

    .arrow-icon-wrapper {
        position: absolute;
        top: 34px;
        left: 17px;
    }

    .team-text {
        padding: 32px 0 20px 70px;
        font-size: 16px;
    }
}


@media screen and (min-width: 900px) {
    .team-text {
        font-size: 20px;
        padding: 32px 0 20px 70px;
    }

    .team-text span {
        font-size: 18px;
    }

    .arrow-icon-wrapper {
        position: absolute;
        top: 34px;
        left: 25px;
    }

}

@media screen and (min-width: 947px) {
    .p-container {
        margin: 20px 10px;
    }

    .back-text p {
        text-align: left;
        margin: 10px;
        font-size: 16px;
    }

    .arrow-icon-wrapper {
        position: absolute;
        top: 34px;
        left: 25px;
    }

}


/*.team-text:before {*/
/*    position: absolute;*/
/*    content: " ";*/
/*    width: 100%;*/
/*    height: 130%;*/
/*    top: -18px;*/
/*    left: -1px;*/
/*    transform: skewY(6deg);*/
/*    z-index: -2;*/
/*    box-shadow: -4px 2px 44px rgba(0, 0, 0, 0.12);*/
/*    background: white;*/
/*    border:1px solid #d4d4d3;*/
/*    */
/*}*/

.arrow-icon-wrapper {
    position: absolute;
    top: 34px;
    left: 17px;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    position: absolute;
    bottom: -28px;
    width: 100%
}

.swiper-pagination-bullet {
    width: 11px;
    height: 11px;
}

.swiper-pagination-container {
    position: relative;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}


.swiper-pagination {
    position: absolute;
    text-align: left;
    transition: 300ms opacity;
    /*transform: translate3d(0, 0, 0);*/
    z-index: 10;
}

.navigation-btns {
    display: flex;
    position: absolute;
    bottom: -5px;
    right: 0;
    z-index: 555;
}


.swiper-button-next,
.swiper-button-prev {
    width: 30px;
    height: 30px;
    box-shadow: -4px 4px 20px rgba(0, 173, 239, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.swiper-button-next {
    background: url("../../assets/images/Forwards_Hover.svg") center no-repeat;
}

.swiper-button-prev {
    background: url("../../assets/images/Backwards_Hover.svg") center no-repeat;
    margin-right: 40px;
}

.swiper-button-next.swiper-button-disabled {
    background: url("../../assets/images/Forwards_Grey.svg") center no-repeat;
}

.swiper-button-prev.swiper-button-disabled {
    background: url("../../assets/images/Backwards_Grey.svg") center no-repeat;
}

/*.swiper-button-next:hover {*/
/*    background: url("../../assets/images/Forwards_Hover.svg") center no-repeat;*/
/*}*/

/*.swiper-button-prev:hover {*/
/*    background: url("../../assets/images/Backwards_Hover.svg") center no-repeat;*/
/*}*/

