.contacts-block{
    margin-bottom: 40px;
}

.contacts-block h3{
    text-align: left;
    margin-bottom: 32px;
    font-weight: 500;
    width: 100%;
    color: #00ADEF;
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 26px;
    display: flex;
    align-items: center;
    caret-color: transparent;

}

.contacts-block h3 img{
    width: 30px;
}

.footer-logo{
    display: flex;
    justify-content: left;
    margin-bottom: 22px;
}

.contacts-box{
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
}

.form-box{
    width: 100%;
    margin-bottom: 20px;
}
.required{
    font-family: Objektiv-Mk2, sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #F0410C;
    margin-top: -20px;
    margin-bottom: 5px;
    margin-left: 20px;
}

.address-box{
    width: 100%;
    align-self: end;
}

.form-inner-row{
    display: flex;
    flex-direction: column;
}
.row-input{
    width: 100%;
    height: 56px;
    padding: 16px;
    background: #f5f5f5;
    margin-bottom: 28px;
    font-size: 17px;
    color: #434040;
    font-family: Objektiv-Mk2, sans-serif;
    position: relative;

}

.row-input:focus{
    border:1px solid #00ADEF;
}


.select {
    background: url('../../assets/images/grey-arrow-down.png') no-repeat 97% 50% #f5f5f5;
    -webkit-appearance: none;
    color: #abaaa9;
}

.select option:hover{
 background: #00ADEF;
}

.select:focus{
    background: url('../../assets/images/arrow-down.png ') no-repeat right #f5f5f5;
    background-position-x: 244px;
    border:1px solid #00ADEF;
    color: #434040;
}

.row-input.select.option-disabled{
    color: #d51919;
}

.error{
    border:1px solid #F0410C;
}

.contacts-image-wrapper{
    width: 100%;
    height: 100%;
}

.contacts-image-wrapper img{
    width: 100%;
    height: auto;
    display: block;
}

.bottom-text{
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 14px;
    color: #434040;
    margin-top: 20px;
}

@media screen and (min-width: 941px) {
    .form-inner-row{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .row-input{
        width: 100%;
    }
    .address-box {
        width: 27%;
    }
}




.row-input::placeholder
{
    font-family: Objektiv-Mk2, sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #abaaa9;
}

.textarea{
    resize: none;
    padding: 16px;
    width: 100%;
    background: #f5f5f5;
    min-height: 112px;
    font-family: Objektiv-Mk2, sans-serif;
    color: #434040;

}

.textarea::placeholder{
    font-size: 17px;
    font-weight: 400;
    font-family: Objektiv-Mk2, sans-serif;
    color: #abaaa9;
}

.textarea:focus{
    border:1px solid #00ADEF;
}
.text-error{
    border:1px solid #F0410C;
}

.checkbox-and-btn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (min-width: 680px){
    .checkbox-and-btn{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

}

.checkbox{
    display: flex;
    align-items: center;
}
.checkbox-label{
    font-size: 17px;
    font-weight: 400;
    color: #434040;
    font-family: Objektiv-Mk2, sans-serif;
}
.send-btn{
    color: #00adef;
    font-size: 32px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 16px;
    font-family: solano-gothic-pro-mvb, sans-serif;
    text-transform: uppercase;
    border: 2px solid #00ADEF;
    padding: 4px 15px 4px 4px;
}

.send-btn:disabled{
    cursor: not-allowed;
}


/*.send-btn:disabled:hover .submit-span{*/
/*  opacity: 0;*/
/*    margin-left: -8px;*/
/*}*/

/*.send-btn:disabled:hover .submit-p{*/
/*    margin-right: 20px;*/
/*    margin-left: 13px;*/
/*}*/

.submit-span{
    transform: translateX(-40px);
    opacity: 0;
    transition: 0.2s;
    color: #00ADEF;
}

.submit-p{
    transition: 0.2s;
    margin-right: 20px;
    margin-left: 5px;
    padding: 0;
}

.send-btn:hover .submit-span{
    color: #00ADEF;
    transform: translateX(-10px);
    opacity: 1;
    visibility: visible;
    margin-left: 13px;
}


.send-btn:hover .submit-p{
margin-right: 8px;
    margin-left: 6px;
}



.social-icons-btns{
    display: flex;
    margin-top: 18px;
}

.social-icons-btns a{
   width:48px;
    height: 48px;
    text-decoration: none;
    font-size: 20px;
    color: #00adef;
    background: rgba(0, 173, 239, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    cursor: pointer;
}

.address-box{
    text-align: left;
}

.address-box p{
    color: #6D737A;
    font-size: 16px;
    font-weight: 400;
}

@media screen and (min-width: 690px) {
    .contacts-box{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .form-box{
        width: 100%;
    }
    /*.address-box{*/
    /*    width: 35%;*/
    /*    align-self: end;*/
    /*}*/
}
